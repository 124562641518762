<template>
  <div>
    <ConfirmModal ref="DeleteConfirmModal" @ok="OnConfirmed" content="This will be removed. Continue?" />
    <CJumbotron v-if="!capability.organization.id">
      <h1 class="display-3">Permissions required</h1>
      <p class="lead">Organizational authority required.</p>
      <CButton color="primary" @click="$router.push('/install/organizations')"><CIcon name="cil-building" />&nbsp;Go to get permission</CButton>
    </CJumbotron>
    <div v-else>
      <CRow class="justify-content-center">
        <CCol xl="12" xxl="10">
          <Search @search="onSearch" v-if="!is_view">
            <BranchForm title="New Branch" ref="AddModal" @payload="onAddPayload" />
            <BulkUploadResult ref="BulkResultModal" title="Result" :result="bulkUploadContext" />
            <ConfirmModal ref="ConfirmModal" :content="`${bulkUploadContext.items.length} items will be uploaded. Continue?`" @ok="bulkSubmit" />
            <ProgressModal ref="ProgressModal" :counter="UploadProgressCounter" @cancel="onStopBulkUpload" />
            <CTableWrapper 
              :items="list"
              :fields="FIELDS"
              header-icon="cil-room"
              :add-btn="true"
              :count="count"
              :caption="$t('cloud.title.branch')"
              hover
              small
              fixed
              :checkable="false"
              @import-example="onImportExample"
              @import="onImport"
              @add="onAdd"
              @view="onView"
              @delete="onDelete"
              @delete-checked-items="onDeleteCheckedItems"
            />
            <CRow class="justify-content-end" v-if="pageTotal > 1">
              <CPagination
                :activePage.sync="pageCurrent"
                :pages="pageTotal"
                @update:activePage="onUpdatePage"
              >
              </CPagination>
              <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
            </CRow>
          </Search>
        </CCol>
      </CRow>
      <BranchView v-if="is_view"
        :branch="selected_branch"
        @movePage="onMovePage"
        @onEdit="onEditPayload"
      >
      </BranchView>
    </div>
    <input type="file" id="_upload_file" hidden accept=".csv" @change="onFileChange"/>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import Search from '@/containers/Search.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import ProgressModal from '@/components/modals/ProgressModal.vue'
import BulkUploadResult from '@/components/modals/BulkResultModal.vue'
import CTableWrapper from '@/components/EdxTable'
import BranchView from './BranchView.vue'
import BranchForm from './BranchForm.vue'
import { mapState } from 'vuex'

export default {
  name: 'SetupBranches',
  components: {
    Search,
    CTableWrapper,
    BulkUploadResult,
    ConfirmModal,
    ProgressModal,
    BranchView,
    BranchForm,
  },
  data () {
    return {
      FIELDS: [
        // {key: 'checkbox', label: '', _style:'width:50px;'},
        {key: 'name', label: 'Name', _classes: 'align-middle'},
        {key: 'region_name', label: 'Region', _classes: 'align-middle'},
        {key: 'address', label: 'Address', _classes: 'align-middle'},
        {key: 'ops', label: 'Operations', _style: 'width: 100px;'}
      ],
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      searchText: '',
      list: [],

      bulkUploadContext: {
        header: [],
        items: [],
        current: 0,
        maxPage: 0,
        okCnt: 0,
        errors: []
      },

      confirm_show: false,

      // view 관련 데이터
      is_view : false,
      selected_branch : null,
      selected_branch_index : null, 
    }
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    UploadProgressCounter() {
      return parseInt((Math.min(this.bulkUploadContext.current, this.bulkUploadContext.maxPage) / Math.max(this.bulkUploadContext.maxPage, 1)) * 100);
    }
  },
  methods: {
    getList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/branches/${query_string}`)
        .then(result => {
          this.list = result.data.list;
          for(let el of result.data.list){
            el['address'] = el['addr1'] + ' ' + el['addr2'];
          }
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        });
    },
    addBulk() {
      if (this.bulkUploadContext.current > this.bulkUploadContext.maxPage) {
        this.$refs.ProgressModal.close();
        this.$refs.BulkResultModal.open();
        this.getList();
        // console.log(this.bulkUploadContext.errors);
        return;
      }
      // 현재 페이지의 N개 ($resource.BULK_UPLOAD_CNT) 를 잘라 targetItems로 만든다.
      let targetItems = this.bulkUploadContext.items.slice(
        this.$resource.BULK_UPLOAD_CNT*this.bulkUploadContext.current,
        Math.min(this.$resource.BULK_UPLOAD_CNT*(this.bulkUploadContext.current+1), this.bulkUploadContext.items.length)
      );

      this.$api.AddBranches((result) => {
        // console.log(result.data);
        for (result of result.data.result) {
          if (!result.result) {
            this.bulkUploadContext.errors.push(result);
          } else {
            this.bulkUploadContext.okCnt++;
          }
        }
        this.bulkUploadContext.current++;
        this.addBulk();
      }, (error) => {
        console.error(error);
        // TODO. 에러 메시지 표시
      }, {
        page_total: this.bulkUploadContext.maxPage,
        page_current: this.bulkUploadContext.current,
        items: targetItems,
        headers: this.bulkUploadContext.header
      });
    },
    onSearch(text) {
      this.searchText = text;
      this.getList();
    },
    onImport() {
      document.getElementById('_upload_file').value=null;
      document.getElementById('_upload_file').click();
    },
    onImportExample() {
      const sample_format = [{
        "name":"서울시청",
        "addr1":"서울특별시",
        "addr2":"서울시청",
        "lat":"37.566580",
        "lng":"126.978445",
        "region_name":"Input region_name here",
      }]
      let filename = "branches_sample.csv"
      this.$utility.DownloadCSV(sample_format,filename);
    },
    onAdd() {
      this.$refs.AddModal.open();
    },
    onAddPayload(payload) {
      axios.post(`/api/branches/`, payload)
        .then(() => {
          this.getList();
          this.$notify.success({
            title: this.$t('cloud.title.success'),
            message: this.$t('cloud.title.request_done'),
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: this.$t('cloud.title.error'),
            message: `${this.$t('cloud.title.request_fail')}: ${error.response.data.error}`,
            offset: 30
          });
        })
    },
    onEditPayload(payload) {
      axios.put(`/api/branches/${this.selected_branch.id}/`, payload)
        .then(result => {
          this.getList();
          this.selected_branch = result.data;
          this.$notify.success({
            title: this.$t('cloud.title.success'),
            message: this.$t('cloud.title.request_done'),
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: this.$t('cloud.title.error'),
            message: `${this.$t('cloud.title.request_fail')}: ${error.response.data.error}`,
            offset: 30
          });
        })
    },
    onFileChange(e) {
      const files = e.target.files;
      const reader = new FileReader;
      if (files.length !== 1) return;
      reader.onload = event => {
        let payload = event.target.result;
        payload = payload.replace(/[\r\n]+$/, '');
        payload = this.$utility.CSVToArray(payload);

        // Context init & cascaded function call
        this.bulkUploadContext.maxPage = parseInt((payload.length-1) / this.$resource.BULK_UPLOAD_CNT);
        this.bulkUploadContext.current = 0;
        this.bulkUploadContext.header = payload[0];
        this.bulkUploadContext.items = payload.slice(1);
        this.bulkUploadContext.okCnt = 0;
        this.bulkUploadContext.errors = [];
        this.$refs.ConfirmModal.open();
      }
      reader.readAsText(files[0]);
    },
    onUpdatePage() {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    bulkSubmit() {
      this.$refs.ProgressModal.open();
      this.addBulk();
    },
    onStopBulkUpload() {
      this.bulkUploadContext.current = this.bulkUploadContext.maxPage + 1;
      this.$refs.ProgressModal.close();
    },

    onView(id, index) {
      this.is_view = true;
      this.selected_branch = this.extractBranchById(id);
      this.selected_branch_index = index;
    },
    onMovePage(type){
      if(type==="branches"){
        this.is_view=false;
      }else if(type==="before_branch"){
        this.selected_branch_index = this.selected_branch_index-1;
        if(this.selected_branch_index < 0 ){
          this.selected_branch_index = this.list.length-1;
        }
        this.selected_branch = this.list[this.selected_branch_index];
      }else if(type==="after_branch"){
        this.selected_branch_index = this.selected_branch_index+1;
        if(this.selected_branch_index >= this.list.length){
          this.selected_branch_index = 0;
        }
        this.selected_branch = this.list[this.selected_branch_index];
      }
    },
    // onBranches: function(){
    //   this.is_view=false;
    // },
    // onBeforeBranch : function(){
    //   this.selected_branch_index = this.selected_branch_index-1;
    //   if(this.selected_branch_index < 0 ){
    //     this.selected_branch_index = this.list.length-1;
    //   }
    //   this.selected_branch = this.list[this.selected_branch_index];
    // },
    // onAfterBranch : function(){
    //   this.selected_branch_index = this.selected_branch_index+1;
    //   if(this.selected_branch_index >= this.list.length){
    //     this.selected_branch_index = 0;
    //   }
    //   this.selected_branch = this.list[this.selected_branch_index];
    // },
    onDelete(id) {
      this.$refs.DeleteConfirmModal.open(id);
    },
    onDeleteCheckedItems(delete_items){
      const fd = new FormData;
      let id_list = delete_items.map((item)=>{
        return item.id;
      })
      fd.append("id_list", JSON.stringify(id_list))
      axios.delete(`/api/branches/`, {data:fd})
        .then(() => {
          this.getList();
          this.$notify.success({
            title: this.$t('cloud.title.success'),
            message: this.$t('cloud.title.request_done'),
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: this.$t('cloud.title.error'),
            message: `${this.$t('cloud.title.request_fail')}: ${error.response.data.error}`,
            offset: 30
          });
        });
    },

    OnConfirmed(payload) {
      axios.delete(`/api/branches/${payload}/`)
        .then(() => {
          this.getList();
          this.$notify.success({
            title: this.$t('cloud.title.success'),
            message: this.$t('cloud.title.request_done'),
            offset: 30
          });
        })
        .catch(e => {
          console.error(e);
        });
    },

    extractBranchById(id){
      for(let i=0; i<this.list.length; i++){
        if(this.list[i].id===id){
          return this.list[i];
        }
      }
      console.log("list has not id("+id+")");
      return null;
    },
    
  }
}
</script>
