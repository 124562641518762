<template>
  <div>
    <ConfirmModal ref="DeleteSectionConfirmModal" @ok="onConfirmed" content="This will be removed. Continue?" />
    <SectionAdd :branch="branch" ref="AddModal" @payload="onPayload" />
    <BranchForm ref="EditModal" 
      @payload="onEditBranchPayload"
      :branch="branch"
      title="Edit Branch"
    />
    <CRow class="justify-content-center">
      <CCol xl="12" xxl="10">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CRow class="justify-content-between">
                <el-button class="ml-2" type="text" icon="el-icon-arrow-left" @click="onMovePage('branches')">Back</el-button>
                <span class="my-2">
                  <strong class="h4">{{branch.name}}</strong>
                </span>
                <el-button class="mr-2" type="primary" icon="el-icon-edit-outline" @click="onBranchEdit" />
              </CRow>
            </slot>
          </CCardHeader>

          <CCardBody>
            <div class="d-flex flex-wrap justify-content-left">
              <div class="custom-limit-width mx-4">
                <GoogleMap
                  :search="false"
                  :draggable="false"
                  :lat="branch.lat"
                  :lng="branch.lng"
                  height="280px"
                />
              </div>
              <div>
                <CCallout class="w-2">
                  <small class="text-muted">{{$t('cloud.title.region')}}</small><br>
                  <strong class="h5">{{branch.region_name}}</strong><br><br>
                  <small class="text-muted">{{$t('cloud.title.address')}}</small><br>
                  <strong class="h5">{{address}}</strong><br><br>
                  <small class="text-muted">{{$t('cloud.title.section')}}</small><br>
                  <strong class="h5">{{count}}</strong><br>
                </CCallout>
              </div>
            </div>
          </CCardBody>
        </CCard>

        <CTableWrapper
          :items="sections"
          :fields="SECTIONS_FIELDS"
          header-icon="cil-cursor"
          :add-btn="true"
          :count="count"
          :caption="$t('cloud.title.section')"
          hover
          small
          fixed
          :checkable="true"
          :importBtn="false"
          :viewBtn="false"
          editBtn
          @add="onAdd"
          @edit="onEdit"
          @delete="onDelete"
          @delete-checked-items="onDeleteCheckedItems"
        >
        </CTableWrapper>
        <CRow class="justify-content-end" v-if="pageTotal > 1">
          <CPagination
            :activePage.sync="pageCurrent"
            :pages="pageTotal"
            @update:activePage="onUpdatePage"
          >
          </CPagination>
          <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import CTableWrapper from '@/components/EdxTable'
import GoogleMap from '@/components/GoogleMapLoader.vue'
import SectionAdd from './SectionAdd.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import BranchForm from './BranchForm.vue'

export default {
  name: 'SetupBrnachView',
  components: {
    CTableWrapper,
    GoogleMap,
    SectionAdd,
    ConfirmModal,
    BranchForm,
  },
  props : {
    branch : {type:Object, default:function(){return {}}},
  },
  data(){
    return {
      SECTIONS_FIELDS: [
        {key: 'checkbox', label: '', _style:'width:50px;'},
        {key: 'name', label: this.$t('cloud.title.name')},
        {key: 'device_name', label: 'Device'},
        {key: 'ops', label: 'Operations', _style: 'width:100px;'}
      ],
      sections : [],
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0
    }
  },
  mounted(){
    this.getSections();
  },
  // watch : {
  //   branch : function(){
  //     // 섹션 동기화를 위해
  //     this.getSections();
  //   }
  // },
  computed: {
    address :function(){
      if(this.branch.addr1===undefined)
        return this.branch.addr2;
      else if(this.branch.addr2===undefined)
        return this.branch.addr1;
      else
        return `${this.branch.addr1+" "+this.branch.addr2}`;
    },
  },
  methods: {
    getSections(){
      let query_string = '';
      const params = {
        search: this.searchText,
        page: this.pageCurrent,
        branch:this.branch.id,
      }
      if(params){
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/sections/${query_string}`)
        .then(result => {
          this.sections = result.data.list;
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.querying = false;
        })
    },

    onMovePage(type){
      this.$emit('movePage', type)
    },

    onAdd(){
      this.$refs.AddModal.open({id: null, name: null, device:null, ch:null});
    },
    onEdit(id, order, section){
      if (!section.device) {
        this.$refs.AddModal.open({id: id, name: section.name, device:null, ch:null});
        return;
      }
      axios.get(`/api/devices/${section.device}/`)
        .then(result => {
          this.$refs.AddModal.open({id: id, name: section.name, device:result.data, ch:section.ch});
        })
    },
    onBranchEdit(){
      this.$refs.EditModal.open();
    },
    
    onUpdatePage(){
      this.pageDirectSet = ""+this.pageCurrent;
      this.getSections();

    },
    onChangePageDirectSet(){
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getSections();

    },
    onPayload(payload) {
      payload.branch = this.branch.id;
      payload.region = this.branch.region;
      if (payload.id) {
        axios.put(`/api/sections/${payload.id}/`, payload)
          .then(() => {
            this.getSections();
            this.$notify.success({
              title: this.$t('cloud.title.success'),
              message: this.$t('cloud.title.request_done'),
              offset: 30
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify.error({
              title: this.$t('cloud.title.error'),
              message: `${this.$t('cloud.title.request_fail')}: ${error.response.data.error}`,
              offset: 30
            });
          })
      } else {
        axios.post(`/api/sections/`, payload)
          .then(() => {
            this.getSections();
            this.$notify.success({
              title: this.$t('cloud.title.success'),
              message: this.$t('cloud.title.request_done'),
              offset: 30
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify.error({
              title: this.$t('cloud.title.error'),
              message: `${this.$t('cloud.title.request_fail')}: ${error.response.data.error}`,
              offset: 30
            });
          })
      }
    },

    onEditBranchPayload(payload){
      this.$emit('onEdit', payload);
    },

    onDelete(id){
      this.$refs.DeleteSectionConfirmModal.open(id);
    },
    onConfirmed(payload) {
      axios.delete(`/api/sections/${payload}/`)
        .then(() => {
          this.getSections();
          this.$notify.success({
            title: this.$t('cloud.title.success'),
            message: this.$t('cloud.title.request_done'),
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: this.$t('cloud.title.error'),
            message: `${this.$t('cloud.title.request_fail')}: ${error.response.data.error}`,
            offset: 30
          });
        });
    },
    onDeleteCheckedItems(delete_items){
      const fd = new FormData;
      let id_list = delete_items.map((item)=>{
        return item.id;
      })
      fd.append("id_list", JSON.stringify(id_list))
      axios.delete(`/api/sections/`, {data:fd})
        .then(() => {
          this.getSections();
          this.$notify.success({
            title: this.$t('cloud.title.success'),
            message: this.$t('cloud.title.request_done'),
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: this.$t('cloud.title.error'),
            message: `${this.$t('cloud.title.request_fail')}: ${error.response.data.error}`,
            offset: 30
          });
        });
    }
  }
}
</script>
